<template>
  <div class="pr-6">
    <div v-if="!isPropertySelected">
      <div class="pt-0">
        <hb-empty-state :message="'Please select a Property to continue'" :showBtn="false">
        </hb-empty-state>
      </div>
    </div>
    <div v-else>
      <template v-if="isStatesLoading">
        <v-row class="justify-center ma-0 pa-0">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
      </template>
      <template v-else>
        <AutomationWarnings v-if="missingActions.length" :missing-actions="missingActions"></AutomationWarnings>
        <div class="mt-4">
          <AutomationHeader v-if="automationInfo.name || automationInfo.property_description" :automation-info="automationInfo" :template="'property'"></AutomationHeader>
          <AutomationAccordian v-if="workflows.length" :workflows="workflows" :template="'property'" :selectedAutomationType="automationInfo" :selectedPropertyId="property.id"></AutomationAccordian>
          <hb-empty-state v-else-if="workflows.length === 0" :message="'No spaces exists in the selected property'" :showBtn="false"></hb-empty-state>
        </div>
      </template>
    </div>

  </div>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import AutomationAccordian from "./AutomationAccordian.vue";
import AUTOMATION from '@/constants/automation.js';
import AutomationHeader from "./AutomationHeader.vue";
import AutomationWarnings from "./AutomationWarnings"
import { EventBus } from "../../../EventBus";
export default {
  name: "PropertyTemplate",
  data() {
    return {
      isPropertySelected: false,
      isStatesLoading: true,
    };
  },
  props: {
    property: {
      type: Object,
      required: false
    },
    automationInfo:
    {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    AutomationAccordian,
    AutomationWarnings,
    AutomationHeader
  },
  mounted() {
    this.isPropertySelected = this.property?.id ? true : false;
  },
  computed: {
    ...mapGetters({
      workflows: 'automationStore/getAllWorkflows',
      missingActions: "automationStore/getMissingActions"
    }),
  },
  async created() {
    this.AUTOMATION = AUTOMATION;
    this.loadAutomations(this.property);
  },
  methods: {
    ...mapActions({
      fetchAllWorkflows: 'automationStore/fetchAllWorkflows',
    }),
    async loadAutomations(property = {}){
      let { id = null } = property;
      if(id && this.automationInfo && this.automationInfo?.id){
        await this.fetchAllWorkflows({
            automation_type_id: this.automationInfo.id,
            property_id: id,
        });
        this.isStatesLoading = false;
      }
    }
  },
  watch: {
    async property(val) {
      this.isPropertySelected = !!val?.id;
      if (!this.isPropertySelected) return;
      this.isStatesLoading = true;
      if(this.automationInfo && this.automationInfo?.id){
         await this.loadAutomations(val);
      }
      EventBus.$emit('propertyChanged');
    }
  },
};
</script>

<style lang="scss" scoped></style>